/*
 * Note: In the original JS-in-CSS code, there was a commented line "// minHeight : rem(600),".
 * Since it's commented out, it has been omitted in the CSS conversion.
 */

 .textEditor {
    width: 100%;
}

.objective {
    color: #000;
}


.title {
    font-weight: bold;
    font-size: 1.2rem;
}

.description {
    font-weight: 200;
    font-size: 1rem;
}

.text {
    font-weight: lighter;
}
