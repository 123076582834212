.footer {
    padding-top: calc(var(--mantine-spacing-xl) * 1);
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

.aside {
    height: 100%;
}
.logo {
    @media (max-width: $mantine-breakpoint-sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.description {
    @media (max-width: $mantine-breakpoint-sm) {
        margin-top: var(--mantine-spacing-xs);
        text-align: center;
    }
}

.inner {
    display: flex;
    justify-content: space-between;

    @media (max-width: $mantine-breakpoint-sm) {
        flex-direction: column;
        align-items: center;
    }
}

.groups {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mantine-breakpoint-sm) {
        display: none;
    }
}

.link {
    color : #000;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color : #aaa
    }
}
.afterFooter {
    display: flex;
    align-items: center;

    @media (max-width: $mantine-breakpoint-sm) {
        flex-direction: column;
    }
}

.social {
    @media (max-width: $mantine-breakpoint-sm) {
        margin-top: var(--mantine-spacing-xs);
    }
}
