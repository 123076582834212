/*
 * Note: The original JS-in-CSS code did not contain any unconvertable JavaScript variables.
 */

 .grid {
    padding-top: 20px;
}

.icon {
    margin-right: 12px;
}

.link {
    text-decoration: none;
}

.viewTitle {
    padding-top: 1.5rem;
    border-bottom: 1px solid #ccc;
}

.matterViewDescriptionLabel {
    font-weight: bold;
}

.viewMatter {
    padding: 1.5rem;
    text-decoration: none;
    color: orange;
}
