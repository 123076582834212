.closeDocumentButton {
    cursor: pointer;
}

.sidebarSettings {
    position: sticky;
    top: 0;
    z-index: 10;
    border-left : 1px solid #aaa;
    width       : 400px;
  }


 .mainContent {
    flex : 1;
 }

 .viewLocation {
     padding: 1.5rem;
 }
