.contentContainer {
    margin-top: rem(50);
    margin-left: rem(150);
    margin-right: rem(150);
}

.floatingBanner {
    top: 300px;
    position: sticky;
    padding-top: 0px;
    width: 500px;
    height: 420px;
    right: rem(2);
}
