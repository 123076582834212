

.panel {
    background-color: #fff;
    padding-bottom: 32px;
    border-radius: 0.25rem;
    margin-bottom: 12px;
    padding-right: 32px;
    padding-left: 32px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 8px;
    /* boxShadow value replaced with 0 as the original value was dynamically assigned in JavaScript */
    box-shadow: 6px 12px 40px 11px rgba(0,0,0,0);
    font-size: 14px;
    border: 1px solid #d0d0d0;
}

.content {
    padding-top: 8px;
}
