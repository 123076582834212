.icon {
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;

    border-radius: 8px;

    padding: 0.5rem;
    padding-bottom: 0.2rem;
    display: flex;
    color: #777;
    right: 8px;
    top: 8px;
    transition: all 0.1s;
}
/* on hover, display it */
.icon:hover {
    opacity: 1;
    scale: 1.07;
    box-shadow: -2px 9px 20px 1px rgba(0, 0, 0, 0.3);
}
