.style_wrapper__iSjvs {
    padding-top: calc(1.25rem * var(--mantine-scale));
    padding-bottom: calc(3.125rem * var(--mantine-scale));
}

.style_item__KBn15 {
    display: flex;
}

.style_itemIcon__t0Z74 {
    padding: var(--mantine-spacing-xs);
    margin-right: var(--mantine-spacing-md);
}

.style_itemTitle__ZWogC {
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
}

.style_supTitle__hlVM7 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: var(--mantine-font-size-sm);
    color: var(--mantine-color-blue-light-color);
    letter-spacing: calc(0.03125rem * var(--mantine-scale));
}

.style_title__HC4vD {
    line-height: 1;
    text-align: center;
    margin-top: var(--mantine-spacing-xl);
}

.style_description__8KN2o {
    text-align: center;
    margin-top: var(--mantine-spacing-xs);
}

.style_highlight__CHOtU {
    background-color: var(--mantine-color-blue-light);
    padding: calc(0.3125rem * var(--mantine-scale));
    padding-top: 0;
    border-radius: var(--mantine-radius-sm);
    display: inline-block;
    color: inherit;
}

[data-mantine-color-scheme='dark'] .style_highlight__CHOtU {
    color: var(--mantine-color-white);
}

.BannerSEO_card__03ekB {
    background-color: var(--mantine-color-body);
}

.BannerSEO_title__4y9h3 {
    font-weight: bold;
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    line-height: 1.2;
}

.BannerSEO_body__mn6x_ {
    padding: var(--mantine-spacing-md);
}

.BannerSEO_tile__Qm981:hover {
    transition: transform 0.4s ease-in-out;
    transform: scale(1.01);
}

.BannerSEO_card__03ekB:hover {
    transition: 0.4s ease-in-out;
    background-color: #f3f7f4;
}

.style_dropzone__4LYzE {
    border: 0rem dotted #aaa;
    border-radius: 1em;
    min-width: calc(18.75rem * var(--mantine-scale));
}

.m_d46a4834 {
  position: relative;
  border: calc(0.0625rem * var(--mantine-scale)) dashed;
  color: var(--mantine-color-text);
  padding: var(--mantine-spacing-md);
  border-radius: var(--dropzone-radius);
  cursor: pointer;
  user-select: none;
  transition:
    background-color 100ms ease,
    border-color 100ms ease;
}

  .m_d46a4834:where([data-loading]),
  .m_d46a4834:where(:not([data-activate-on-click])) {
    cursor: default;
  }

  :where([data-mantine-color-scheme='light']) .m_d46a4834 {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_d46a4834 {
    background-color: var(--mantine-color-dark-6);
    border-color: var(--mantine-color-dark-4);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_d46a4834:where([data-accept]) {
    background-color: var(--dropzone-accept-bg);
    border-color: var(--dropzone-accept-bg);
    color: var(--dropzone-accept-color);
  }

  .m_d46a4834:where([data-reject]) {
    background-color: var(--dropzone-reject-bg);
    border-color: var(--dropzone-reject-bg);
    color: var(--dropzone-reject-color);
  }

.m_b85f7144 {
  pointer-events: none;
  user-select: none;
}

.m_b85f7144:where([data-enable-pointer-events]) {
    pointer-events: all;
  }

.m_96f6e9ad {
  position: fixed;
  inset: 0;
  background-color: var(--mantine-color-body);
  display: flex;
  flex-direction: column;
  padding: var(--mantine-spacing-xs);
  transition: opacity 100ms ease;
}

.m_96f6e9ad .m_7946116d {
    flex: 1;
  }

.style_text__0hAhU {
    text-decoration: none;
}

.ActionsGrid_card__Vp2FU {
    background-color: var(--mantine-color-gray-0);
}
    [data-mantine-color-scheme='dark'] .ActionsGrid_card__Vp2FU {
    background-color: var(--mantine-color-dark-6);
}

.ActionsGrid_title__BqBnr {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    font-weight: 700;
}

.ActionsGrid_item__3_iQS {
    border-radius: var(--mantine-radius-md);
    /* height: rem(90px); */
    background-color: var(--mantine-color-white);
}

[data-mantine-color-scheme='dark'] .ActionsGrid_item__3_iQS {
    background-color: var(--mantine-color-dark-7);
}

.ActionsGrid_item__3_iQS {
    transition:
        box-shadow 150ms ease,
        transform 100ms ease;
}

@media (hover: hover) {
    .ActionsGrid_item__3_iQS:hover {
        box-shadow: var(--mantine-shadow-md);
        transform: scale(1.05);
    }
}

@media (hover: none) {
    .ActionsGrid_item__3_iQS:active {
        box-shadow: var(--mantine-shadow-md);
        transform: scale(1.05);
    }
}

.style_content__YPq9w > div > div {
    min-height: calc(6.25rem * var(--mantine-scale));
}



.style_panel__RdJ_D {
    background-color: #fff;
    padding-bottom: calc(2rem * var(--mantine-scale));
    border-radius: 0.25rem;
    margin-bottom: calc(0.75rem * var(--mantine-scale));
    padding-right: calc(2rem * var(--mantine-scale));
    padding-left: calc(2rem * var(--mantine-scale));
    margin-right: calc(1.25rem * var(--mantine-scale));
    margin-left: calc(1.25rem * var(--mantine-scale));
    padding-top: calc(0.5rem * var(--mantine-scale));
    /* boxShadow value replaced with 0 as the original value was dynamically assigned in JavaScript */
    box-shadow: 6px 12px 40px 11px rgba(0,0,0,0);
    font-size: calc(0.875rem * var(--mantine-scale));
    border: calc(0.0625rem * var(--mantine-scale)) solid #d0d0d0;
}

.style_content__9ei8j {
    padding-top: calc(0.5rem * var(--mantine-scale));
}

/*
 * Note: In the original JS-in-CSS code, there was a commented line "// minHeight : rem(600),".
 * Since it's commented out, it has been omitted in the CSS conversion.
 */

 .style_textEditor__h8dPT {
    width: 100%;
}

.style_objective__flRzX {
    color: #000;
}


.style_title__6NLT5 {
    font-weight: bold;
    font-size: 1.2rem;
}

.style_description__ZXd81 {
    font-weight: 200;
    font-size: 1rem;
}

.style_text__atjbD {
    font-weight: lighter;
}



.style_link__l4Jcf {
    text-decoration: none;
}

/*
Unconvertable JS-in-CSS elements:
- export const useStyles = createStyles(_theme => ({...}); This is a function that CSS cannot handle.
*/

.style_transcript__6x5i0 {
    width: 100%;
}

.style_listItem__7atLN {
    list-style: none;
}

/*
 * Note: In the original JS-in-CSS code, there were two instances of JavaScript variables/functions:
 * 1. theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.green[6])
 * 2. rem(6) and rem(100)
 * These have been commented out in the converted CSS and need to be replaced with actual CSS values.
 */

 .style_chat__YxtAX::before {
    /*backgroundImage: replace this with a valid CSS linear gradient value;*/
    position: absolute;
    content: "";
    bottom: 0;
    /*width: replace this with a valid CSS unit value;*/
    left: 0;
    top: 0;
}

.style_root__uNKf9 {
    display: flex;
    box-shadow: -2px 9px 20px 1px rgba(0,0,0,0.1);
    background-image: linear-gradient(
      -60deg,
      var(--mantine-color-blue-0),
      var(--mantine-color-blue-1)
    );
    /* padding: calc(var(--mantine-spacing-xl) * 1.5); */
    border-radius: var(--mantine-radius-md);
  }

@media (max-width: 48em) {

.style_root__uNKf9 {
      flex-direction: column
  }
    }

.style_debugTextLink__E2m7L {
    /*textDecoration: underline;
    textUnderlineOffset: 3;*/
}

.style_titleInput__PKtZN {
    borderRadius: xl;
}

.style_title__IMYvm {
    fontWeight: 200;
}

.style_text__tdjLR {
    textDecoration: none;
}

.style_sideGrid__ciwPg {

}

.style_transcription__UP7Wc {
    width: 100%;
    /*minHeight: replace this with a valid CSS unit value;*/
}

.style_textEditor__8Rm7I {
    /*minHeight: replace this with a valid CSS unit value;*/
    width: 100%;
}

.style_link__dYHa8 {
    textDecoration: none;
}

.style_viewTitle__pq0zj {
    borderBottom: calc(0.0625rem * var(--mantine-scale)) solid #ccc;
    paddingTop: 1.5rem;
}

.style_chatViewDescriptionLabel__i8oMn {
    fontWeight: bold;
}

.style_viewChat__1bYiq {
    textDecoration: none;
    padding: 1.5rem;
    color: orange;
}

/*
 * Note: In the original JS-in-CSS code, there were some variables and functions (theme.fn.linearGradient, theme.colors.blue[8], theme.colors.cyan[3] and rem) which are not convertible to pure CSS.
 */


 .style_panel__1kdcE {
    transition: background-color 0.2s ease;
 }


 .style_panel__1kdcE:hover {
        background-color: #f3f3f3;
      }

 .style_text__ypXLf {
     text-decoration: none;
 }

 .style_icon__UMK5i {
     margin-right: calc(0.5rem * var(--mantine-scale));
 }

 .style_contactViewDescriptionLabel__TUIGV {
     font-weight: bold;
 }

 .style_viewContact__J6vSE {
     padding: 1.5rem;
 }

.style_icon__3QUxV {
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;

    border-radius: calc(0.5rem * var(--mantine-scale));

    padding: 0.5rem;
    padding-bottom: 0.2rem;
    display: flex;
    color: #777;
    right: calc(0.5rem * var(--mantine-scale));
    top: calc(0.5rem * var(--mantine-scale));
    transition: all 0.1s;
}
/* on hover, display it */
.style_icon__3QUxV:hover {
    opacity: 1;
    scale: 1.07;
    box-shadow: -2px 9px 20px 1px rgba(0, 0, 0, 0.3);
}

.ContentSection_researchReference__v0Ams {
    border-radius: calc(1.25rem * var(--mantine-scale));
    margin-bottom: calc(1.875rem * var(--mantine-scale));
    border-top: calc(0.0625rem * var(--mantine-scale)) dotted #ddd;
}

/*
 * Note: The original JS-in-CSS code did not contain any unconvertable JavaScript variables.
 */

 .style_grid__aH8Nn {
    padding-top: calc(1.25rem * var(--mantine-scale));
}

.style_icon__pT1z7 {
    margin-right: calc(0.75rem * var(--mantine-scale));
}

.style_link__TmkuW {
    text-decoration: none;
}

.style_viewTitle__Zt4e7 {
    padding-top: 1.5rem;
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid #ccc;
}

.style_matterViewDescriptionLabel__zhgpz {
    font-weight: bold;
}

.style_viewMatter__Rqnt0 {
    padding: 1.5rem;
    text-decoration: none;
    color: orange;
}

/*
 * Note: In the original JS-in-CSS code, there were uses of JavaScript variables and functions such as `theme.fn.linearGradient(0, '#fff', theme.colors.blue[8], theme.colors.cyan[3], '#fff')` and `rem(1)`.
 * Since these cannot be directly translated to CSS, they have been replaced with placeholder values for the purpose of this conversion.
 */


.style_stepper__iO_tf {
    position: fixed;
    bottom: calc(12.5rem * var(--mantine-scale));
    right: calc(6.25rem * var(--mantine-scale));
    left: calc(31.25rem * var(--mantine-scale));
}

.style_completeButton__d1PCP {
    /* border-radius: 50%; */
    padding-left: 0;
    box-shadow: 1px 5px 14px -2px rgba(0, 0, 0, 0.24);
    padding: calc(1rem * var(--mantine-scale));
    position: fixed;
    font-size: 1.5em;
    cursor: pointer;
    bottom: calc(3.75rem * var(--mantine-scale));
    color: #ccc;
    right: calc(3.75rem * var(--mantine-scale));
}

.style_text__3KFdt {
    text-decoration: none;
}

.style_icon__9ikwQ {
    margin-right: calc(0.75rem * var(--mantine-scale));
}

.style_panel__7ceIL:hover {
    background-color: #fcfcfc;
    transition: transform 0.1s ease-in-out;
    transform: scale(1.015);
}

.style_panel__7ceIL::before {
    content: "";
    position: absolute;
    top: calc(0.5rem * var(--mantine-scale));
    bottom: calc(0.5rem * var(--mantine-scale));
    left: 0;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.05);
    width: 1rem; /* Placeholder value for `rem(1)` */
    background-image: linear-gradient(
        0deg,
        #fff,
        #0000ff,
        #00ffff,
        #fff
    ); /* Placeholder value for `theme.fn.linearGradient(0, '#fff', theme.colors.blue[8], theme.colors.cyan[3], '#fff')` */
}

.style_hoverPanel__Tm4m9 {
    transition: background-color 0.2s ease;
 }
    .style_hoverPanel__Tm4m9:hover {
        background-color: #f3f3f3;
      }


 .style_kanbanDropArea__c7byP {
    cursor: pointer;
 }


 .style_kanbanDropArea__c7byP:hover {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.05);
      }

.Pathway_closeDocumentButton__6snvv {
    cursor: pointer;
}

.Pathway_sidebarSettings__Y2uOu {
    position: sticky;
    top: 0;
    z-index: 10;
    border-left : calc(0.0625rem * var(--mantine-scale)) solid #aaa;
    width       : calc(25rem * var(--mantine-scale));
  }


 .Pathway_mainContent__D5smq {
    flex : 1;
 }

 .Pathway_viewLocation__zH2u_ {
     padding: 1.5rem;
 }

.style_contentContainer__E5gsi {
    margin-top: calc(3.125rem * var(--mantine-scale));
    margin-left: calc(9.375rem * var(--mantine-scale));
    margin-right: calc(9.375rem * var(--mantine-scale));
}

.style_floatingBanner__8ih_J {
    top: calc(18.75rem * var(--mantine-scale));
    position: sticky;
    padding-top: 0rem;
    width: calc(31.25rem * var(--mantine-scale));
    height: calc(26.25rem * var(--mantine-scale));
    right: calc(0.125rem * var(--mantine-scale));
}

.NewPatentPanel_googleMapContainer__hyBKt {
    height: 100vh;
    width: 100%;
}

.NewPatentPanel_button__qki8a:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.05);
}

.style_logoText__TMQOM {
    text-decoration: none;
    color: #000;
}

.HeaderMegaMenu_link__8aP0_ {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: var(--mantine-color-black);
}
    [data-mantine-color-scheme='dark'] .HeaderMegaMenu_link__8aP0_ {
    color: var(--mantine-color-white);
}
    .HeaderMegaMenu_link__8aP0_ {
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
}

    @media (max-width: 48em) {.HeaderMegaMenu_link__8aP0_ {
        height: calc(2.625rem * var(--mantine-scale));
        width: 100%
}
    }

    @media (hover: hover) {
    .HeaderMegaMenu_link__8aP0_:hover {
        background-color: var(--mantine-color-gray-0);
    }
        [data-mantine-color-scheme='dark'] .HeaderMegaMenu_link__8aP0_:hover {
        background-color: var(--mantine-color-dark-6);
    }
}

    @media (hover: none) {
    .HeaderMegaMenu_link__8aP0_:active {
        background-color: var(--mantine-color-gray-0);
    }
        [data-mantine-color-scheme='dark'] .HeaderMegaMenu_link__8aP0_:active {
        background-color: var(--mantine-color-dark-6);
    }
}

.HeaderMegaMenu_logoText__ulsV7 {
    text-decoration: none;
    color: #000;
}

.HeaderMegaMenu_subLink__g_H86 {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
    margin-bottom: var(--mantine-spacing-xl);
}

@media (hover: hover) {
    .HeaderMegaMenu_subLink__g_H86:hover {
        background-color: var(--mantine-color-gray-0);
    }
        [data-mantine-color-scheme='dark'] .HeaderMegaMenu_subLink__g_H86:hover {
        background-color: var(--mantine-color-dark-7);
    }
}

@media (hover: none) {
    .HeaderMegaMenu_subLink__g_H86:active {
        background-color: var(--mantine-color-gray-0);
    }
        [data-mantine-color-scheme='dark'] .HeaderMegaMenu_subLink__g_H86:active {
        background-color: var(--mantine-color-dark-7);
    }
}

.HeaderMegaMenu_dropdownFooter__TGJYG {
    background-color: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .HeaderMegaMenu_dropdownFooter__TGJYG {
    background-color: var(--mantine-color-dark-7);
}

.HeaderMegaMenu_dropdownFooter__TGJYG {
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: calc(0.0625rem * var(--mantine-scale)) solid
        var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .HeaderMegaMenu_dropdownFooter__TGJYG {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid
        var(--mantine-color-dark-5);
}

.style_footer__uSY6I {
    padding-top: calc(var(--mantine-spacing-xl) * 1);
    background-color: var(--mantine-color-gray-0);
}
    [data-mantine-color-scheme='dark'] .style_footer__uSY6I {
    background-color: var(--mantine-color-dark-6);
}
    .style_footer__uSY6I {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}
    [data-mantine-color-scheme='dark'] .style_footer__uSY6I {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}

@media (max-width: 48em) {

.style_logo__x1yvt {
        display: flex;
        flex-direction: column;
        align-items: center
}
    }

@media (max-width: 48em) {

.style_description__849tn {
        margin-top: var(--mantine-spacing-xs);
        text-align: center
}
    }

.style_inner__X2kAA {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 48em) {

.style_inner__X2kAA {
        flex-direction: column;
        align-items: center
}
    }

.style_groups__pNoUf {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 48em) {

.style_groups__pNoUf {
        display: none
}
    }

.style_link__gGmF9 {
    color : #000;
    text-decoration: none;
}

.style_link__gGmF9:hover {
        text-decoration: underline;
        color : #aaa
    }
.style_afterFooter__mwGlJ {
    display: flex;
    align-items: center;
}
@media (max-width: 48em) {
.style_afterFooter__mwGlJ {
        flex-direction: column
}
    }

@media (max-width: 48em) {

.style_social__QYjzO {
        margin-top: var(--mantine-spacing-xs)
}
    }

.style_footer__1kUVB {
    padding-top: calc(var(--mantine-spacing-xl) * 1);
    background-color: var(--mantine-color-gray-0);
}
    [data-mantine-color-scheme='dark'] .style_footer__1kUVB {
    background-color: var(--mantine-color-dark-6);
}
    .style_footer__1kUVB {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}
    [data-mantine-color-scheme='dark'] .style_footer__1kUVB {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}

.style_aside__DKbrL {
    height: 100%;
}
@media (max-width: 48em) {
.style_logo__MtukP {
        display: flex;
        flex-direction: column;
        align-items: center
}
    }

@media (max-width: 48em) {

.style_description__Nnvgt {
        margin-top: var(--mantine-spacing-xs);
        text-align: center
}
    }

.style_inner__wzfp7 {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 48em) {

.style_inner__wzfp7 {
        flex-direction: column;
        align-items: center
}
    }

.style_groups__xadQ0 {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 48em) {

.style_groups__xadQ0 {
        display: none
}
    }

.style_link__Iw7lV {
    color : #000;
    text-decoration: none;
}

.style_link__Iw7lV:hover {
        text-decoration: underline;
        color : #aaa
    }
.style_afterFooter__lB9xG {
    display: flex;
    align-items: center;
}
@media (max-width: 48em) {
.style_afterFooter__lB9xG {
        flex-direction: column
}
    }

@media (max-width: 48em) {

.style_social__s7AjL {
        margin-top: var(--mantine-spacing-xs)
}
    }

.TemplateApp_asideIcon__WmGJ6:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.3);
}

.TemplateApp_asideIcon__WmGJ6 {
    cursor: pointer;
}

