/*
Unconvertable JS-in-CSS elements:
- export const useStyles = createStyles(_theme => ({...}); This is a function that CSS cannot handle.
*/

.transcript {
    width: 100%;
}

.listItem {
    list-style: none;
}
