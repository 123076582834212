/*
 * Note: In the original JS-in-CSS code, there were two instances of JavaScript variables/functions:
 * 1. theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.green[6])
 * 2. rem(6) and rem(100)
 * These have been commented out in the converted CSS and need to be replaced with actual CSS values.
 */

 .chat::before {
    /*backgroundImage: replace this with a valid CSS linear gradient value;*/
    position: absolute;
    content: "";
    bottom: 0;
    /*width: replace this with a valid CSS unit value;*/
    left: 0;
    top: 0;
}

.root {
    display: flex;
    box-shadow: -2px 9px 20px 1px rgba(0,0,0,0.1);
    background-image: linear-gradient(
      -60deg,
      var(--mantine-color-blue-0),
      var(--mantine-color-blue-1)
    );
    /* padding: calc(var(--mantine-spacing-xl) * 1.5); */
    border-radius: var(--mantine-radius-md);



    @media (max-width: $mantine-breakpoint-sm) {
      flex-direction: column;
    }
  }

.debugTextLink {
    /*textDecoration: underline;
    textUnderlineOffset: 3;*/
}

.titleInput {
    borderRadius: xl;
}

.title {
    fontWeight: 200;
}

.text {
    textDecoration: none;
}

.sideGrid {

}

.transcription {
    width: 100%;
    /*minHeight: replace this with a valid CSS unit value;*/
}

.textEditor {
    /*minHeight: replace this with a valid CSS unit value;*/
    width: 100%;
}

.link {
    textDecoration: none;
}

.viewTitle {
    borderBottom: 1px solid #ccc;
    paddingTop: 1.5rem;
}

.chatViewDescriptionLabel {
    fontWeight: bold;
}

.viewChat {
    textDecoration: none;
    padding: 1.5rem;
    color: orange;
}
