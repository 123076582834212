.card {
    background-color: var(--mantine-color-body);
}

.title {
    font-weight: bold;
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    line-height: 1.2;
}

.body {
    padding: var(--mantine-spacing-md);
}

.tile:hover {
    transition: transform 0.4s ease-in-out;
    transform: scale(1.01);
}

.card:hover {
    transition: 0.4s ease-in-out;
    background-color: #f3f7f4;
}
