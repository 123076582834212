/*
 * Note: In the original JS-in-CSS code, there were uses of JavaScript variables and functions such as `theme.fn.linearGradient(0, '#fff', theme.colors.blue[8], theme.colors.cyan[3], '#fff')` and `rem(1)`.
 * Since these cannot be directly translated to CSS, they have been replaced with placeholder values for the purpose of this conversion.
 */


.stepper {
    position: fixed;
    bottom: 200px;
    right: 100px;
    left: 500px;
}

.completeButton {
    /* border-radius: 50%; */
    padding-left: 0;
    box-shadow: 1px 5px 14px -2px rgba(0, 0, 0, 0.24);
    padding: 16px;
    position: fixed;
    font-size: 1.5em;
    cursor: pointer;
    bottom: 60px;
    color: #ccc;
    right: 60px;
}

.text {
    text-decoration: none;
}

.icon {
    margin-right: 12px;
}

.panel:hover {
    background-color: #fcfcfc;
    transition: transform 0.1s ease-in-out;
    transform: scale(1.015);
}

.panel::before {
    content: "";
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.05);
    width: 1rem; /* Placeholder value for `rem(1)` */
    background-image: linear-gradient(
        0deg,
        #fff,
        #0000ff,
        #00ffff,
        #fff
    ); /* Placeholder value for `theme.fn.linearGradient(0, '#fff', theme.colors.blue[8], theme.colors.cyan[3], '#fff')` */
}
