/*
 * Note: In the original JS-in-CSS code, there were some variables and functions (theme.fn.linearGradient, theme.colors.blue[8], theme.colors.cyan[3] and rem) which are not convertible to pure CSS.
 */


 .panel {
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #f3f3f3;
      }
 }

 .text {
     text-decoration: none;
 }

 .icon {
     margin-right: rem(8);
 }

 .contactViewDescriptionLabel {
     font-weight: bold;
 }

 .viewContact {
     padding: 1.5rem;
 }
