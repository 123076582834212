.hoverPanel {
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #f3f3f3;
      }
 }


 .kanbanDropArea {
    cursor: pointer;

    &:hover {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.05);
      }
 }
